import { useEffect, useState } from "react";

import { getUsersList } from "../../services/user";
import Error from "../Error";
import Loader from "../Loader";
import Success from "../Success";

import "./UserList.css";

export default function UserList() {
  const initialState = {
    error: "",
    done: "",
    user: null,
    loading: true,
    prevPage: null,
    nextPage: null,
    users: [],
  };

  const [state, setState] = useState(initialState);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadUsers(), [setState]);

  const loadUsers = (page) => {
    setState({ ...state, loading: true });
    getUsersList(page).then((list) => {
      const prevPage = list.pagination.previous;
      const nextPage = list.pagination.next;
      setState({
        ...state,
        loading: false,
        users: list.items,
        prevPage: prevPage === list.pagination.first ? null : prevPage,
        nextPage,
      });
    });
  };

  const onPageChange = (page) => () => {
    loadUsers(page);
  };

  const renderUsers = (user, id) => {
    const className = id % 2 ? "row-even" : "row-odd";
    const fullName = `${user.firstName} ${user.lastName}`;

    let role = "-";
    if (user.roles.includes("CR")) {
      role = "CR";
    }

    return (
      <div className={`user-rows ${className}`} key={user.ID}>
        <div className="user-table-row user-loginid">{user.loginID}</div>
        <div className="user-table-row user-name">{fullName}</div>
        <div className="user-table-row user-role">{role}</div>
      </div>
    );
  };

  const renderPages = () => {
    const { prevPage, nextPage } = state;

    return (
      <div className="pagination">
        <button type="button" disabled={!prevPage} onClick={onPageChange()}>
          first
        </button>
        <button type="button" disabled={!prevPage} onClick={onPageChange(prevPage)}>
          prev
        </button>
        <button type="button" disabled={!nextPage} onClick={onPageChange(nextPage)}>
          next
        </button>
      </div>
    );
  };

  const renderContent = () => {
    if (state.users) {
      return (
        <div className="users-list-table">
          <div className="users-head">
            <div className="user-table-row user-loginid">LoginID</div>
            <div className="user-table-row user-name">Name</div>
            <div className="user-table-row user-role">Role</div>
          </div>
          {state.users.map(renderUsers)}
          {renderPages()}
        </div>
      );
    }

    return <div className="users-list-table">Nothing to display</div>;
  };

  return (
    <div className="users-list">
      <h3>
        <a className="switch-list" href="/list">
          Orgs List
        </a>
        <span className="switch-list">CR Users List</span>
      </h3>
      <Loader loading={state.loading}>
        <Error error={state.error} onDismiss={() => setState({ ...state, error: false })} />
        <Success message={state.done} onDismiss={() => setState({ ...state, done: false })} />
        <div className="users-list-body">{renderContent()}</div>
      </Loader>
    </div>
  );
}
