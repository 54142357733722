import { useEffect, useState } from "react";
import exclamationCircleOutlined from "@iconify/icons-ant-design/exclamation-circle-outlined";
import { Icon } from "@iconify/react";
import { isNull } from "lodash";
import moment from "moment";
import Select from "react-select";

import Colors from "@core/ui-legacy/themes/colors";

import Orgs from "../../services/org";
import Loader from "../Loader";
import customStyles from "../OrgEdit/selectorStyle";

import "./OrgList.css";

export default function OrgList() {
  const initialState = {
    loading: true,
    status: "",
    name: "",
    accountCode: "",
    next: null,
    prev: null,
    orgs: [],
  };

  const [state, setState] = useState(initialState);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadOrgs(), [setState]);

  const loadOrgs = (offset = null, name = "", status = "", accountCode = "") => {
    setState({ ...state, loading: true });
    Orgs.getList(name, status.value, offset, undefined, accountCode).then((list) => {
      setState({
        ...state,
        loading: false,
        orgs: list.items,
        next: list.nextOffset,
        prev: list.previousOffset,
        name,
        status,
        accountCode,
      });
    });
  };

  const onPageChange = (offset) => () => {
    const { name, status, accountCode } = state;
    loadOrgs(offset, name, status, accountCode);
  };

  const onSearch = async (e) => {
    e.preventDefault();
    const { name, status, accountCode } = state;
    loadOrgs(null, name, status, accountCode);
  };

  const onReset = async (e) => {
    e.preventDefault();
    setState(initialState);
    loadOrgs();
  };

  const renderOrgs = (org, id) => {
    const className = id % 2 ? "row-even" : "row-odd";
    const isReviewNeeded = org.cardPrograms.find((program) => isNull(program.isPrefunded));

    const accountCodes = org.cardPrograms
      .map((card) => (card?.comdataID?.accountCode ? card?.comdataID?.accountCode : ""))
      .filter((e) => !!e)
      .join(", ");

    return (
      <div className={`org-rows ${className}`} key={org.ID}>
        <div className="org-table-row org-name">
          <a href={`/edit/${org.ID}`}>{org.name}</a>
          <br />
          <span className="orgs-list-id">{org.ID}</span>
        </div>
        <div className="org-table-row org-created-on">
          {moment(org.createdOn).format("YYYY-MM-DD, HH:mm")}
        </div>
        <div className="org-table-row org-list-counter">
          {(accountCodes && accountCodes) || "No codes"}
          {isReviewNeeded ? (
            <div className="icon-wrapper-tooltip">
              <Icon
                icon={exclamationCircleOutlined}
                style={{
                  color: Colors.danger_red,
                  fontSize: "1.4rem",
                  marginLeft: "0.5rem",
                  verticalAlign: "sub",
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="org-table-row org-list-counter">{org.status || "not defined"}</div>
        <div className="org-table-row org-list-counter">{org.kycAMLComplete ? "Yes" : "No"}</div>
      </div>
    );
  };

  const renderPages = () => {
    const { next, prev } = state;

    return (
      <div className="pagination">
        <button type="button" disabled={!prev} onClick={onPageChange(0)}>
          first
        </button>
        <button type="button" disabled={!prev} onClick={onPageChange(prev)}>
          prev
        </button>
        <button type="button" disabled={!next} onClick={onPageChange(next)}>
          next
        </button>
      </div>
    );
  };

  const renderContent = () => {
    if (state.orgs) {
      return (
        <div className="orgs-list-table-inner">
          <div className="org-head">
            <div className="org-table-row org-name">Name</div>
            <div className="org-table-row org-created-on">Created On</div>
            <div className="org-table-row org-list-counter">Account codes</div>
            <div className="org-table-row org-list-counter">Status</div>
            <div className="org-table-row org-list-counter">kycComplete</div>
          </div>
          {state.orgs.map(renderOrgs)}
          {renderPages()}
        </div>
      );
    }

    return <div className="orgs-list-table">Nothing to display</div>;
  };

  const statusOptions = [
    { label: "", value: "" },
    { label: "onboarding", value: "onboarding" },
    { label: "active", value: "active" },
    { label: "archived", value: "archived" },
  ];

  return (
    <div className="orgs-list">
      <h3>
        <span className="switch-list">Orgs List</span>
        <a className="switch-list" href="/users">
          CR Users List
        </a>
      </h3>
      <Loader loading={state.loading}>
        <div className="orgs-list-body">
          <div className="orgs-list-table">
            <div className="orgs-list-control-section">
              <div className="orgs-list-control">
                <a className="create-new-org-button" href="/create">
                  <button type="button">Create New Org</button>
                </a>
                <form onSubmit={onSearch} autoComplete="off">
                  <div className="search-reset search-input">
                    <button type="button" onClick={onReset}>
                      Reset
                    </button>
                  </div>
                  <div className="search-submit search-input">
                    <button type="submit">Search </button>
                  </div>
                  <div className="search-status search-input">
                    <label>
                      By Status
                      <Select
                        className="cc-selector"
                        isSearchable={false}
                        options={statusOptions}
                        value={state.status}
                        onChange={(val) => setState({ ...state, status: val })}
                        styles={customStyles(150)}
                      />
                    </label>
                  </div>
                  <div className="search-account-code search-input">
                    <label htmlFor="search-orgs-by-account-code">
                      By Account code:
                      <input
                        id="search-orgs-by-account-code"
                        type="text"
                        value={state.accountCode}
                        onChange={(e) => setState({ ...state, accountCode: e.target.value })}
                      />
                    </label>
                  </div>
                  <div className="search-name search-input">
                    <label htmlFor="search-orgs-by-name">
                      By Name:
                      <input
                        id="search-orgs-by-name"
                        type="text"
                        value={state.name}
                        onChange={(e) => setState({ ...state, name: e.target.value })}
                      />
                    </label>
                  </div>
                </form>
              </div>
            </div>
            {renderContent()}
          </div>
        </div>
      </Loader>
    </div>
  );
}
